import { graphql } from "gatsby";
import React from "react";
import Faq from "../components/faq/faq";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Seo from "../components/seo";
import Title from "../components/title/title";

// markup
const FAQPage = ({ location, data }) => {
  const scrollToForm = "#";

  const questions = [
    {
      question: `How does Master Menu Management work with 100+ locations?`,
      answer: `Orders.co creates an Admin account with access to all connected locations.
                Menu updates can be made to all stores from one account.
                Individual locations will also have access to their own menus for changes.`,
    },
    {
      question: `How does Orders.co work with third party apps?`,
      answer: `Orders.co communicates with delivery apps in multiple ways.
                We receive order information while sending confirmation & cancellation info,
                we import and export menus, as well as sales/reports.`,
    },
    {
      question: `Do you provide the tablets to the restaurants you work with?`,
      answer: `Orders.co provides our All-In-One device; equipped with an integrated
                tablet and ticket printer.`,
    },
    {
      question: `How many locations or concepts can be connected?`,
      answer: `You can integrate as many locations and concepts as you need.`,
    },
    {
      question: `How will we receive analytics for all our stores?`,
      answer: `Admin accounts have access to reporting for all connected locations and concepts.
                You can view reports with custom filters for all locations/brands, providers,
                or even view specific days.`,
    },
    {
      question: `Do you also provide delivery services?`,
      answer: `Orders.co allows for both in-house and UberEats fulfillment on website orders.`,
    },
    {
      question: `Can you help me create a menu QR code?`,
      answer: `Yes, submit a request to our 24/7 support team and a Menu QR code will be generated
                absolutely free of charge.`,
    },
    {
      question: `Will my ordering website rank on Google?`,
      answer: `Restaurant partners can work with your optimization team to improve your Google
                ranking and gain exposure for your business.`,
    },
    {
      question: `How does my menu become a website?`,
      answer: `Once your menu has been created in the Orders.co Master Menu it is linked to your
                website and can be live in moments.`,
    },
    {
      question: `How does Orders.co process credit card payments?`,
      answer: `Currently, Orders.co is partnered with Stripe for all online purchase processing.
                Restaurant partners looking to setup an ordering website are required to have or create
                a Stripe account.`,
    },
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `How does Orders.co process website transactions?`,
      answer: `Orders.co has partnered with Stripe for all online credit card processing.
                All restaurant partners are required to create and pair․`,
    },
    {
      question: `Does Orders.co offer a delivery service for web orders?`,
      answer: `Yes, Orders.co has partnered with UberEats to offer delivery for restaurants that do not
                have delivery staff. The delivery fee will be charged directly to the customer at checkout.
                There is also an option for In-House delivery for restaurants with existing staff.`,
    },
    {
      question: `Can we connect our existing ticket printers to Orders.co?`,
      answer: `You can connect your existing ticket printer to the Orders.co device in the settings panel.
                The printer must be internet compatible and be connected to the same network as the Orders.co
                device. For a full list of compatible printers, contact our support team.`,
    },
    {
      question: `How does Orders.co integration benefit my Ghost Kitchen?`,
      answer: `Ghost Kitchens are a unique new style of restaurant that primarily generate revenue through
                delivery-only channels. In doing so, many of these businesses will have multiple Brands across
                a variety of delivery platforms. This results in an abundance of tablets needed to manage menus
                and service orders. Orders.co consolidates all of this into a single device.
                Generating one uniform order list for all connected stores.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How will orders from multiple virtual stores be displayed in Orders.co?`,
      answer: `All your integrated virtual stores are shown together in the Orders.co “Orders” list.
                Next to each order is the Brand Name and Platform, so staff can easily identify every order.`,
    },
    {
      question: `How can we integrate an Orders.co ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
    {
      question: `Which delivery platforms are supported by Orders.co?`,
      answer: `Currently Orders.co supports Grubhub, Postmates, UberEats, and DoorDash. With many more on
                the way. Restaurant partners are also encouraged to request new platform integrations.`,
    },
    {
      question: `How does Menu Sync Work?`,
      answer: `Menu Sync links your Master Menu to all connected providers so that all updates are
                uniformly pushed everywhere. Saving you time and energy.`,
    },
    {
      question: `What kind of Technical Support do orders.co offer?`,
      answer: `Orders.co has 24/7 technical and menu support teams to help our restaurant
                partners with anything they need.`,
    },
    {
      question: `How much does Orders.co cost?`,
      answer: `Orders.co has 3 affordable plans based on your unique needs. You never have to pay
                for unnecessary features. See the “Pricing” page for a detailed breakdown. `,
    },
    {
      question: `I charge different prices on each Delivery App. Does Orders.co support this?`,
      answer: `Yes! Orders.co’s Master Menu will allow you to set unique pricing on any given
                menu item for all providers.`,
    },
    {
      question: `How long does it take for menu updates to be pushed to my live menus?`,
      answer: `All menu updates are pushed to your live menus in 1 hour or less.
                For more urgent updates, you can contact an Orders.co representative.`,
    },
    {
      question: `How much does setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `What options does Orders.co have for Order Confirmation?`,
      answer: `Orders.co offers both Manual and Auto-Confirmation for incoming orders. Manual
                confirmation is given by default, as it allows for prep-time adjustments at confirmation,
                but a request for Auto can be made to our 24/7 support team at any time.`,
    },
    {
      question: `How can we integrate the ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
    {
      question: `How does your software work with our existing POS?`,
      answer: `Orders.co works with a wide range of POS companies to directly inject orders
                from your various platforms directly into your POS System. Online orders are aggregated
                into our system and then pushed to your in-store device.`,
    },
    {
      question: `Do you offer a reporting feature?`,
      answer: `Orders.co offers a simple, yet effective, reporting suite that allows users to review
                a variety of reports on a range of metrix to optimize online operations. Administrators
                can also save custom filters to view specific platforms, item performance, or times
                with ease.`,
    },
    {
      question: `Do you provide the tablets to our stores?`,
      answer: `Orders.co provides our unique all-in-one device to every restaurant partner free of charge.
                An integrated tablet allows interaction with our full management system and an integrated
                printer makes order processing a breeze.`,
    },
    {
      question: `How will Menu Sync help our day-to-day?`,
      answer: `Orders.co’s Menu Sync allows in-store staff to update item availability with just one click
                for all connected platforms. Saving time and reducing costly errors.`,
    },
    {
      question: `How does Orders.co benefit our employees?`,
      answer: `Orders.co provides an abundance of daily operation benefits to employees from in-store to
                the administrative level. Admins can review reports from individual stores or a collective,
                making tough decisions near trivial. Ground level employees can save precious time fulfilling
                online orders by only needing to use one device. Lowering wait times for in-store customers
                and freeing up valuable counter space`,
    },
    {
      question: `How does your sales reporting work?`,
      answer: `Orders.co sales reporting automatically captures all sales process through our system
                and populates reports daily. Users can review these reports from an Admin account on the
                desktop version of the Orders.co dashboard.`,
    },
    {
      question: `How many channels can I connect?`,
      answer: `As long as the platform is supported by Orders.co, you can integrate as many sales
                channels as you want! This includes hundreds of locations and virtual concepts.`,
    },
    {
      question: `How much does sales reporting cost?`,
      answer: `Orders.co sales reporting is provided to restaurant partners on any of our
                subscription plans free of charge.`,
    },
    {
      question: `What types of reports can I review?`,
      answer: `Orders.co sales reports offer a variety of filters for sales optimization,
                including, but not limited to, top selling items, sales by hour, and sales by provider.`,
    },
    {
      question: `How does the custom filtered reports work?`,
      answer: `Restaurant partners can generate reports with their desired filters and save them as
                presets. Allowing for quicker access of important information in the future. Custom filters
                can be specified down to a particular day or sales channel.`,
    },
  ];

  return (
    <>
      <Seo
        title={"FAQ"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Title underlined={true} layout={"bolder"} h1={true}>
          Frequently Asked Questions
        </Title>
        <Faq title="" items={questions} />
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "frequently-asked-questions" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default FAQPage;
